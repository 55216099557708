import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/system";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";

import {SketchPicker} from 'react-color';
import Box from "@mui/material/Box";
import SliderCom from "../../common/SliderCom";

import TenantAutocomplete from '../../common/TenantAutocomplete';

const FormFields = styled('div')(({theme}) => ({
    height: '100%',
    width: '100%',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingTop: '50px',

}));

const EditorWrapper = styled('div')(({theme}) => ({
    height: '100%',
    width: '100%',
    padding: theme.spacing(3.25, 4, 4, 4),

}));


const ErrorMessage = styled('div')(({theme}) => ({
    fontSize: 13,

}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false,
    rowStyle: (rowdata) => {
        return {
            backgroundColor: rowdata.active ? "#fff" : 'rgba(252,234,234,0.37)',
        };
    },

};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginRight: 4,
    width: 90,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


const marksMany = [
    {
        value: 0,
    },
    {
        value: 20,
    },
    {
        value: 30,
    },
    {
        value: 40,
    },
    {
        value: 50,
    },
    {
        value: 60,
    },
    {
        value: 100,
    },
    {
        value: 200,
    },
];


const marksFew = [
    {
        value: 1,
    },
    {
        value: 2,
    },
    {
        value: 3,
    },
    {
        value: 4,
    },
    {
        value: 5,
    },
    {
        value: 6,
    },
    {
        value: 7,
    },
    {
        value: 8,
    },
];


function GroupUpdate(props) {

    const {
        userUseCases,
        groups,
        selectedTenant,
        setSelectedTenant,
        handleChange,
        updatedGroup,
        error,
        readOnly,
        isNew,
        handleSelectedLocation,
        handleSelectedGroup,
        setUpdatedGroup,
        user,
        setTenant,
    } = props;

    const {t, i18n} = useTranslation();
    const [data, setData] = useState();
    const [constraint1, setConstraint1] = useState(updatedGroup.maxDuration != null);
    const [constraint2, setConstraint2] = useState(updatedGroup.maxForward != null);
    const [constraint3, setConstraint3] = useState(updatedGroup.maxCount != null);

    return (
        <FormFields>
            <form autoComplete='off'>
                <Grid container spacing={2}>

                    <Grid item md={4} xs={12}>
                        <Grid container spacing={2}>
                            <TenantAutocomplete
                                error={error.tenants}
                                setSelectedTenant={setSelectedTenant}
                                selectedTenant={selectedTenant}
                                multiple={false}
                                disabled={readOnly || !user.admin?true:false}
                                required={true}
                                user={user}
                                tenantId={user.admin ? ( updatedGroup ? updatedGroup.tenantId : 0 ) : ( user ? user.tenantId : 0 )}
                            />            
                        </Grid>            
                    </Grid>
                    <Grid item md={8} xs={12}></Grid>
                    <Grid item md={12} xs={12}></Grid>
                    <Grid item md={12} xs={12}></Grid>

                    <Grid item md={4} xs={12}>
                        <Grid container spacing={2}>
                            <TextField
                                variant="outlined"
                                autoFocus
                                error={error.name}
                                helperText={error.name}
                                fullWidth
                                disabled={readOnly}
                                id="name"
                                value={updatedGroup.name || ''}
                                onChange={handleChange('name', false)}
                                label={t('GROUP_NAME')}/>
                        </Grid>


                        <Grid item xs={12}
                              sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  paddingTop: 2,
                              }}>
                            <Box sx={{width: 250, height: 100}}>

                                <Typography gutterBottom>{t('MAX_LENGTH')}<Checkbox
                                    inputProps={{'aria-label': 'controlled'}}
                                    checked={updatedGroup.maxDuration != null}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            setUpdatedGroup({...updatedGroup, 'maxDuration': 14});
                                        } else {
                                            setUpdatedGroup({...updatedGroup, 'maxDuration': null});
                                        }
                                    }}
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                /></Typography>

                                <SliderCom
                                    sx={{paddingTop: 5}}
                                    aria-label="maxDuration"
                                    value={updatedGroup.maxDuration != null ? updatedGroup.maxDuration : 0}
                                    max={200}
                                    onChange={handleChange("maxDuration")}
                                    marks={marksMany}
                                    valueLabelDisplay="on"
                                    disabled={updatedGroup.maxDuration == null}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={12}
                              sx={{
                                  display: 'flex',
                                  paddingTop: 4,
                                  justifyContent: 'flex-start'
                              }}>
                            <Box sx={{width: 250, height: 100}}>

                                <Typography gutterBottom>{t('MAX_FORWARD')}<Checkbox
                                    inputProps={{'aria-label': 'controlled'}}
                                    checked={updatedGroup.maxForward != null}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            setUpdatedGroup({...updatedGroup, 'maxForward': 20});
                                        } else {
                                            setUpdatedGroup({...updatedGroup, 'maxForward': null});
                                        }
                                    }}
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                /></Typography>

                                <SliderCom
                                    sx={{paddingTop: 5}}
                                    aria-label="maxForward"
                                    value={updatedGroup.maxForward != null ? updatedGroup.maxForward : 0}
                                    max={200}
                                    onChange={handleChange("maxForward")}
                                    marks={marksMany}
                                    valueLabelDisplay="on"
                                    disabled={updatedGroup.maxForward == null}

                                />
                            </Box>

                        </Grid>
                        <Grid item xs={12}
                              sx={{
                                  display: 'flex',
                                  paddingTop: 4,
                                  justifyContent: 'flex-start'
                              }}>
                            <Box sx={{width: 250, height: 100}}>

                                <Typography gutterBottom>{t('MAX_COUNT')}<Checkbox
                                    inputProps={{'aria-label': 'controlled'}}
                                    checked={updatedGroup.maxCount != null}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            setUpdatedGroup({...updatedGroup, 'maxCount': 3});
                                        } else {
                                            setUpdatedGroup({...updatedGroup, 'maxCount': null});
                                        }
                                    }}
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                /></Typography>

                                <SliderCom
                                    sx={{paddingTop: 5}}
                                    aria-label="maxCount"
                                    value={updatedGroup.maxCount != null ? updatedGroup.maxCount : 0}
                                    max={8}
                                    onChange={handleChange("maxCount")}
                                    marks={marksFew}
                                    valueLabelDisplay="on"
                                    disabled={updatedGroup.maxCount == null}

                                />
                            </Box>

                        </Grid>
                    </Grid>


                    <Grid item md={4} xs={12}>
                        <Typography align="left" variant='body2'
                                    sx={{paddingBottom: 5}}>{t('LIGHT_COLOR')}</Typography>
                        <SketchPicker
                            color={updatedGroup.lightColor}
                            onChangeComplete={handleChange("lightColor")}
                        />


                    </Grid>


                    <Grid item md={4} xs={12}>
                        <Typography align="left" variant='body2'
                                    sx={{paddingBottom: 5}}>{t('DARK_COLOR')}</Typography>
                        <SketchPicker
                            color={updatedGroup.darkColor}
                            onChangeComplete={handleChange("darkColor")}
                        />
                    </Grid>


                </Grid>
            </form>
        </FormFields>
    )
        ;
}

GroupUpdate.propTypes = {};
GroupUpdate.defaultProps = {};


export default GroupUpdate;


