import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Grid, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/system";
import { fetchRolesByTenantId } from "../../../redux/actions/role";
import { fetchGroupsByTenantId } from "../../../redux/actions/group";
import { dateTimetoIso } from "../../../utils";

const CloseButton = styled(IconButton)(({ theme }) => ({
    left: 10,
    color: (theme) => theme.palette.primary.contrastText,
}));

function ReceiptDetail(props) {
    const {
        handleClose,
        open,
        receiptDetail,
    } = props;

    const { t } = useTranslation();

    const handleCloseLoc = () => {
        handleClose();
    };

    return (
        <Dialog
            maxWidth="md"
            sx={{
                "& .MuiDialog-paper": {
                    width: "100%",
                    margin: 1,
                },
            }}
            open={open}
            onClose={handleCloseLoc}
        >
            <DialogTitle
                sx={{
                    height: "100px",
                    paddingLeft: 5,
                    paddingRight: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <>
                    <Typography
                        variant="h4"
                        sx={{
                            color: (theme) => theme.palette.background.contrastText,
                            fontWeight: 700,
                        }}
                    >
                        {t('RECEIPT_DETAIL')}
                    </Typography>
                </>

                <CloseButton
                    aria-label="delete"
                    size="small"
                    onClick={handleCloseLoc}
                    sx={{marginTop: '-20px'}}
                >
                    <ClearIcon fontSize="medium" />
                </CloseButton>
            </DialogTitle>

            <DialogContent
                sx={{paddingLeft: '70px'}}
            >
                <Grid container columnSpacing={2} rowSpacing={3} >
                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 600,
                            }}
                        >
                            {t('DATE_FROM')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={0}>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: (theme) => theme.palette.background.contrastText,
                                        backgroundColor: (theme) => theme.palette.background.grey100,
                                        borderRadius: '15px',
                                        paddingLeft: '20px',
                                        marginLeft: '-20px',
                                        marginRight: '20px'
                                    }}
                                >
                                    {dateTimetoIso(receiptDetail.dateFrom)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        color: (theme) => theme.palette.text.disabled, fontWeight: 600,
                                        marginLeft: '-10px',
                                        marginRight: '10px'
                                    }}
                                >
                                    {t('TO2')}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: (theme) => theme.palette.background.contrastText,
                                        backgroundColor: (theme) => theme.palette.background.grey100,
                                        borderRadius: '15px',
                                        paddingLeft: '20px',
                                        marginLeft: '-20px',
                                        marginRight: '20px'
                                    }}
                                >
                                    {dateTimetoIso(receiptDetail.dateTo)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 600,
                            }}
                        >
                            {t('PARKING_PLACE')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: (theme) =>
                                    theme.palette.background.contrastText,
                            }}
                        >
                            {receiptDetail.parkingPlace ? receiptDetail.parkingPlace.name : ''}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 600,
                            }}
                        >
                            {t('LPN')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            variant="h4"
                            sx={{
                                color: (theme) =>
                                    theme.palette.background.contrastText,
                            }}
                        >
                            {receiptDetail.lpn}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 600,
                            }}
                        >
                            {t('ID')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}
                        >
                            {receiptDetail.receiptId}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{
                        borderTop: `1px solid rgba(0,0,0,0.3)`, 
                        marginTop:'15px', 
                        marginBottom:'-20px',
                        marginLeft: '15px',
                        marginRight: '55px'
                    }}></Grid>

                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 600,
                            }}
                        >
                            {t('ADDRESS')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}
                        >
                            {receiptDetail.street}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 600,
                            }}
                        >
                            {t('CITY')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}
                        >
                            {receiptDetail.city + ', ' + receiptDetail.zip}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 600,
                            }}
                        >
                            {t('VAT')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}
                        >
                            {receiptDetail.vatId}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 600,
                            }}
                        >
                            {t('DIC')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}
                        >
                            {receiptDetail.companyId}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{
                        borderTop: `1px solid rgba(0,0,0,0.3)`, 
                        marginTop:'15px', 
                        marginBottom:'-20px',
                        marginLeft: '15px',
                        marginRight: '55px'
                    }}></Grid>

                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 600,
                            }}
                        >
                            {t('AMOUNT')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                                fontWeight: 600,
                            }}
                        >
                            {receiptDetail.amount + ' ' + receiptDetail.currency}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                                fontWeight: 600,
                            }}
                        >
                            {t('DPH')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                                fontWeight: 600,
                            }}
                        >
                            {receiptDetail.tax+' %'}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0,
                    height: 100,
                    width: "100%",
                    marginLeft: '-25px',
                }}
            >
                <Button
                    sx={{
                        width: "150px", margin: 1,
                        fontWeight: 600,
                    }}
                    onClick={handleCloseLoc}
                    color="secondary"
                    variant="outlined"
                >
                    {t("CLOSE")}{" "}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ReceiptDetail.propTypes = {};

const mapStateToProps = (store) => ({
    roles: store.roleData.rolesByTenantId,
    groups: store.groupData.groupsByTenantId,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchRolesByTenantId,
            fetchGroupsByTenantId,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptDetail);
